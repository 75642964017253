import React, { Component } from "react";
import { Col } from "reactstrap";
import { connect } from "react-redux";
import _ from "underscore-contrib";
// import BackgroundImage from 'react-background-image-loader'
import { Link } from "react-router";
import { staticStore } from "../../remoteServer";

// const localImage = '/img/jpg/load.gif'
//const notFoundImg = '/img/jpg/not-available.jpg'

// Иконка блока "Favorites"
const Icon = ({ active }) => (
  <svg>
    {active ? (
      <path
        fill="#fff"
        d="M4.013,18.012c-0.34-0.015-0.604-0.301-0.59-0.641c0.001-0.041,0.007-0.082,0.017-0.122l1.463-5.864
			L0.264,7.494C0.003,7.275-0.03,6.887,0.189,6.627C0.297,6.5,0.452,6.421,0.618,6.41l6.043-0.421l2.268-5.606
			c0.128-0.314,0.488-0.465,0.803-0.336c0.153,0.062,0.274,0.184,0.338,0.336l2.271,5.606l6.043,0.421
			c0.34,0.025,0.594,0.321,0.568,0.66c-0.014,0.165-0.092,0.317-0.219,0.424l-4.639,3.886l1.467,5.869
			c0.084,0.329-0.114,0.664-0.444,0.748c-0.164,0.042-0.339,0.015-0.481-0.076l-5.136-3.207l-5.14,3.207
			C4.257,17.984,4.136,18.018,4.013,18.012z"
      />
    ) : (
      <path
        fill="#fff"
        d="M4.013,18.012c-0.34-0.015-0.604-0.301-0.59-0.641c0.001-0.041,0.007-0.082,0.017-0.122l1.463-5.864L0.264,7.494
			C0.003,7.275-0.03,6.887,0.189,6.627C0.297,6.5,0.452,6.421,0.618,6.41l6.043-0.421l2.268-5.606
			c0.128-0.314,0.488-0.465,0.803-0.336c0.153,0.062,0.274,0.184,0.338,0.336l2.271,5.606l6.043,0.421
			c0.34,0.025,0.594,0.321,0.568,0.66c-0.014,0.165-0.092,0.317-0.219,0.424l-4.639,3.886l1.467,5.869
			c0.084,0.329-0.114,0.664-0.444,0.748c-0.164,0.042-0.339,0.015-0.481-0.076l-5.136-3.207l-5.14,3.207
			C4.257,17.984,4.136,18.018,4.013,18.012z M11.343,6.805l-1.846-4.55l-1.84,4.549C7.569,7.023,7.365,7.172,7.13,7.19L2.223,7.535
			l3.767,3.152c0.182,0.149,0.262,0.392,0.205,0.621l-1.19,4.762l4.171-2.604c0.2-0.125,0.455-0.125,0.655,0l4.172,2.604l-1.19-4.762
			c-0.058-0.229,0.021-0.471,0.201-0.621l3.766-3.152C16.777,7.535,11.43,7.023,11.343,6.805z"
      />
    )}
  </svg>
);

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.item.attributes.image,
      // favorite: this.props.favorite
    };
  }

  // добавление/удаление из избранного (компонент Favorites)
  toggleFavorite(item, e) {
    // блок, который перемещается в правый край страницы
    const tile = e.currentTarget.lastChild;

    if (this.props.favorite) {
      tile.style.cssText = "";

      if (item.type === "design") this.props.deleteFavoriteDesign(item);
      else this.props.deleteFavoriteCollection(item);
    } else {
      // мгновенное перемещение tile-блока на исходную - нюансы страницы "Избранное"
      tile.style.cssText = "transition-duration: 0 0 0 0 0;";
      tile.classList.remove("active");

      if (item.type === "design") this.props.addFavoriteDesign(item);
      else this.props.addFavoriteCollection(item);

      // перемещение блока tile у Favorites
      let positionTo = document
        .querySelector(".favorites-fix")
        .getBoundingClientRect();
      tile.classList.add("active");
      let top = positionTo.top - tile.getBoundingClientRect().top;
      let left = positionTo.left - tile.getBoundingClientRect().left;

      tile.style.cssText = `top:${top}px;left:${left}px;`;
    }
    // this.setState({favorite: !this.props.favorite})
  }

  searchImg(colorStore) {
    if (colorStore.length > 0) {
      const item = colorStore[0];
      const arr = item.split("_");
      const num = _.last(arr);

      let designs_img = [];
      if (this.props.item.attributes.designs.data.length !== 0)
        designs_img = _.filter(
          this.props.item.attributes.designs.data,
          function search(item) {
            if (item.attributes.color)
              if (item.attributes.color.data.id === num) return item;
          }
        );

      let friendly_designs_img = [];
      if (this.props.item.attributes.friendly_designs.data !== [])
        friendly_designs_img = _.filter(
          this.props.item.attributes.friendly_designs.data,
          function search(item) {
            if (item.id === num) return item;
          }
        );

      if (designs_img.length > 0) {
        this.setState({ image: designs_img[0].attributes.image });
      } else if (friendly_designs_img.length > 0) {
        this.setState({ image: friendly_designs_img[0].attributes.image });
      } else {
        this.setState({ image: this.props.item.attributes.image });
      }
    } else {
      this.setState({ image: this.props.item.attributes.image });
    }
  }

  componentWillMount() {
    document.querySelector(".Favorites-fix");

    //Устанавливаем картинку
    if (this.props.main) this.searchImg(this.props.testStore.filter.color);

    // Поиск элемента в фаворитном списке
    // let array = (this.props.design ? this.props.testStore.favorites.designs : this.props.testStore.favorites.collections)
    // let inFavorite = array.some(
    //   (item) => { return item.attributes.name === this.props.item.attributes.name && item.id === this.props.item.id }
    // )
    // this.setState({favorite: inFavorite})
  }

  componentWillReceiveProps(nextProps) {
    this.searchImg(nextProps.testStore.filter.color);
  }

  // появление компонента при загрузке изображения компонента
  handleImgLoaded(e) {
    // поиск родителя с классом 'item-c'
    function func(e) {
      e.classList.contains("item-c")
        ? e.classList.add("item-c-loaded")
        : func(e.parentNode);
    }

    func(e.target);

    // let targetParent = e.target.parentNode.parentNode.parentNode.parentNode;
    // targetParent.classList.contains('item-c') && targetParent.classList.add('item-c-loaded');
    let loadedImagesCount = document.querySelectorAll(".item-c-loaded").length;
    let imagesCount = document.querySelectorAll(".item-c img").length;
    let loader = document.querySelector(".result-filter-loader");
    loader.style.opacity = 1;

    // loader width
    loadedImagesCount &&
      imagesCount &&
      (loader.style.width = `${(loadedImagesCount / imagesCount) * 100}%`);

    // on all loading
    if (imagesCount === loadedImagesCount) {
      setTimeout(() => {
        loader.style.opacity = 0;
      }, 300);
      [...document.querySelectorAll(".item-c .random-block")].map((el) => {
        return el.parentElement.classList.add("item-c-loaded");
      });
    }
    //   (imagesCount === loadedImagesCount) && (
    //   loader.style.opacity = 0 &&
    //     [...document.querySelectorAll('.item-c .random-block')].map((el) => {
    //       return el.parentElement.classList.add('item-c-loaded')
    //     })
    // )
  }

  checkReminders(item) {
    let data = item.attributes.remainders.data;
    if (data.length === 1) {
      return data[0].attributes.value;
    }
    data = data.filter((d) => {
      return d.attributes.city.replace("CityEnum.", "") === this.props.city;
    });
    return data[0].attributes.value;
  }

  render() {
    return (
      <Col xs="3" className="item-c">
        {!this.props.design ? (
          <div
            className="goods-standard"
            onClick={() =>
              (document.querySelector(".result-filter-loader").style.width =
                "0")
            }
          >
            <div className="goods-picher">
              <div className="good-status">
                {this.props.item.attributes.is_new ? (
                  <p>new</p>
                ) : this.props.item.attributes.is_sale ? (
                  <p className="sale">sale</p>
                ) : (
                  ""
                )}
              </div>
              {this.props.disableFavActions ? (
                ""
              ) : (
                <div
                  className={
                    "action-favorits" + (this.props.favorite ? " active" : "")
                  }
                  onClick={this.toggleFavorite.bind(this, this.props.item)}
                >
                  <Icon active={this.props.favorite} />
                  <div className="animated-tile" />
                </div>
              )}
              <Link to={this.props.item._url}>
                <img
                  className="item-picher"
                  src={staticStore + this.state.image}
                  onLoad={this.handleImgLoaded.bind(this)}
                  alt="not found"
                />
                {/*<BackgroundImage className="item-picher" src={staticStore + this.state.image} placeholder={localImage}/>*/}
              </Link>
            </div>
            <Link to={this.props.item._url}>
              <div className="goods-text">
                <div className="good-text-left">
                  <p className="good-text-name">
                    {this.props.item.attributes.name}
                    {this.props.item.attributes.type != null ? (
                      <span className="good-text-type">
                        {this.props.item.attributes.type.data.attributes.name}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="good-text-right">
                  {!this.props.item.attributes.is_sale && (
                    <p className="good-text-price">
                      {this.props.item.type === 'furniture_support' && 'от'} {this.props.item.attributes.price} ₽
                    </p>
                  )}
                </div>
              </div>
            </Link>
          </div>
        ) : (
          <div className="design-item">
            <div className="design-item-pich">
              <img
                className="item-picher"
                src={staticStore + this.props.item.attributes.image}
                onLoad={this.handleImgLoaded.bind(this)}
                alt="not found"
              />
              {/*<BackgroundImage className="item-picher" src={staticStore + this.props.item.attributes.image}*/}
              {/*placeholder={localImage}>*/}
              {/*<div className="something-else"></div>*/}
              {/*</BackgroundImage>*/}
              {this.props.disableFavActions ? (
                ""
              ) : (
                <div
                  className={
                    "action-favorits" + (this.props.favorite ? " active" : "")
                  }
                  onClick={this.toggleFavorite.bind(this, this.props.item)}
                >
                  <Icon active={this.props.favorite} />
                  <div className="animated-tile" />
                </div>
              )}
            </div>
            <div className="design-item-text">
              <p>
                <span className="design-item-name">
                  {this.props.item.attributes.name}
                </span>
                <span className="design-item-remainder">
                  В наличии:{" "}
                  {this.props.item.attributes.remainder ||
                    this.checkReminders(this.props.item)}{" "}
                  п/м
                </span>
              </p>
            </div>
          </div>
        )}
      </Col>
    );
  }
}

export default connect(
  (state) => ({
    testStore: state,
    city: state.city.id,
  }),
  (dispatch) => ({
    addFavoriteCollection: (item) => {
      dispatch({ type: "ADD_FAVORITE_COLLECTION", payload: item });
    },
    deleteFavoriteCollection: (item) => {
      dispatch({ type: "DELETE_FAVORITE_COLLECTION", payload: item });
    },
    addFavoriteDesign: (item) => {
      dispatch({ type: "ADD_FAVORITE_DESIGN", payload: item });
    },
    deleteFavoriteDesign: (item) => {
      dispatch({ type: "DELETE_FAVORITE_DESIGN", payload: item });
    },
  })
)(Item);
