import React, { Component } from 'react'
import closeImg from '../../components/App/img/svg/close.svg'

class PricePopUp extends Component {
   constructor() {
      super()
      this.state = {
         active: !0,
      }
   }

   close() {
      this.setState({ active: !1 })

      let now = new Date()
      let time = now.getTime()
      let expireTime = time + 3600 * 1000 * 24 // 24 hours
      now.setTime(expireTime)
      document.cookie = 'was;expires=' + now.toGMTString() + ';path=/'
   }

   componentWillMount() {
      this.setState(() => {
         let key = 'was'
         let parsed = document.cookie.split(';').map((e) => e.trim())
         for (var e of parsed) if (e === key) return { active: !1 }
         return { active: !0 }
      })
   }

   render() {
      return (
         <div className={'price-pop_up-wr' + (this.state.active ? '' : ' closed')}>
            <div className='pop_up-shadding' onClick={this.close.bind(this)}></div>
            <div className='price-pop_up price_up'>
               <div className='pop_up-close' onClick={this.close.bind(this)}>
                  <img src={closeImg} alt='' />
               </div>
               <h1>
                  Уважаемые партнеры<span>!</span>
               </h1>
               <div className='dash'></div>
               <p>Обращаем Ваше внимание на то,</p>
               <p>
                  что с <span>31.03.2025</span> цены на некоторые позиции изменятся
               </p>
               <div className='pop-up__caution'>Просим Вас быть внимательными при оформлении заказов</div>
            </div>
         </div>
      )
   }
}

export default PricePopUp
